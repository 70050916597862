.bloc {
    width: 300px;
    height: 60px;
    padding: 10px;
    border-radius: 12px;
    background: #ffffff1a;
    display: flex;
    align-items: center;
    position: relative;
    color: var(--primary-text-color);
    justify-content: center;
}

.bloc img {
    width: 106.67px;
    height: 60px;
    border-radius: 6px;
}

.blocIcons img {
    margin-right: 5px;
    margin-top: 5px;
    height: 15px;
    width: 15px;
}

.blocFoused, .bloc:hover {
    background: #ffffff33;
}

.blocActive {
    border: 4px solid var(--focus-border-color);
}
