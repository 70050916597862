.multiple-content-block-tile-container {
    display: flex;
    justify-content: center;
    width: 320px;
    height: 430px;
    border-radius: 12px;
    position: relative;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: ease-out 0.2s;
}

.multiple-content-block-tile-container:hover .multiple-content-block-tile-btn {
    transition: ease-out 0.3s;
    background-color: var(--secondary-color);
}

.multiple-content-block-tile-container:hover {
    transform: translate(0px, -10px);
    box-shadow: 0px 20px 30px rgba(0, 46, 98, 0.3);
}

.multiple-content-block-tile-banner {
    width: 100%;
    height: 60%;
    float: top;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border-radius: 12px;

    @media (max-width: 750px) {
    }
}

.multiple-content-block-tile-info-container {
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    bottom: 0px;
    padding-inline: 10px;
    padding-bottom: 20px;
}

.multiple-content-block-tile-logo {
    width: 160px;
    height: 90px;
    border-radius: 12px;
}

.multiple-content-block-tile-description {
    font-size: 15px;
    color: var(--primary-text-color);
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    height: 60px;
    overflow: hidden;
    line-height: 21px;
    font-weight: 400;
    width: 288px;
}

.multiple-content-block-tile-planInfo {
    font-size: 16px;
    text-align: center;
    color: var(--primary-text-color);
    padding-top: 20px;
}

.multiple-content-block-tile-btn {
    font-family: var(--font-name);
    width: 288px;
    height: 40px;
    font-size: 12px;
    line-height: 13.8px;
    font-weight: 700;
    color: var(--primary-text-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background-color: var(--primary-color);
    font-family: var(--font-name-bold);
}

.multiple-content-price-label{
    font-family: var(--font-name);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.8px;
}
