.channel {
    display: flex;
    width: 660px;
    height: 135px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 12px;
    color: var(--White-Theme, #FFF);
    padding: 10px;
}

.channelFocued, .channel:hover{
    background: var(--white-20, rgba(255, 255, 255, 0.20));
}


.channelFocued .tileBtns, .channel:hover .tileBtns {
    display: flex;
}

.channel img {
    background: #21262A;
}