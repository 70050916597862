.episodeResponsive {
    width: 350px;
    height: 193px;
    color: var(--White-Theme, --primary-text-color);
    border-radius: 12px;
    padding: 10px;
}

.episodeResponsive .tileDetailImg {
    width: 160px;
    height: 90px;
    flex-shrink: 0;
    border-radius: 6px;
}
.episodeResponsiveImage {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    width: 350px;
}

.episodeResponsiveFocused, .episodeResponsive:hover{
    background: var(--white-20, rgba(255, 255, 255, 0.20));
}