.volt-dropdown-input {
    padding: 0.75rem;
    border: 2px solid #ced4da;
    border-radius: 6px;
    font-size: 1rem;
    color: #495057;
    outline: none;
    cursor: pointer;
    width: 100%;
}

.volt-dropdown-input:hover {
    border: 2px solid #a2a2a2;
}

.volt-dropdown-input:focus {
    border: 2px solid var(--primary-color);
}

.volt-panel {
    position: absolute;
    width: auto;
    height: 170px;
    background: var(--background-color);
    border-radius: 4px;
    padding: 6px 0;
    min-width: 160px;
    margin-top: 2px;
    z-index: 9999;
    animation-duration: 0.4s;
    animation-name: panelShow;
}

.volt-panel-hide {
    animation-duration: 0.4s;
    animation-name: panelHide;
}

.ddl-search-input {
    margin: 7px 7px 6px 10px;
    font-size: 15px;
    padding: 0.5rem 2.5rem 0.5rem 41px;
    border-radius: 25px;
    background: #f5f5f5;
    color: var(--text-color);
    border: none;
}

.volt-dropdown-arrow {
    height: 16px;
    width: 16px;
    fill: #000;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

.search-box-wrapper {
    /* background: #f2f2f2; */
    /* border-bottom: 1px solid #e2e2e2; */
    /* border-top-left-radius: 4px; */
    /* border-top-right-radius: 4px; */
    position: relative;
    left: 62px;
}

.search-box-icon {
    position: absolute;
    top: 54%;
    left: 28px;
    transform: translate(-50%, -50%);
    fill: var(--text-color) !important;
    height: 20px;
    width: 20px;
}
.closeLogo{
    position: relative;
    left: 211px;
    bottom: 31px;
    cursor: pointer;
}

.search-box-wrapper .element-wrapper {
    margin-top: 16px;
}

.volt-dropdown-wrapper {
    position: relative;
    display: inline-flex;
}

.volt-panel-option-wrapper {
    border-radius: 6px;
}

.volt-panel-option-wrapper:hover {
    background: #cecece;
    cursor: pointer;
}

.volt-panel-option {
    padding: 8px 10px;
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@keyframes panelShow {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes panelHide {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}
