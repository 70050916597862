.soft-bundle-wrapper {
    background-color: var(--bundle-container-color);
    border-radius: 10px;
    padding-top: 15px;
    padding-bottom: 20px;
}

.soft-bundle-title {
    max-width: 109px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--bundle-background-color);
    color: var(--secondary-text-color);
    padding-inline: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    gap: 4px;
    font-size: 16px;
    line-height: 18px;
    border-radius: 4px;
    margin-inline: auto;
}

.soft-bundle-star-icon {
    fill: var(--secondary-text-color);
    width: 20px;
    height: 20px;
}

.soft-bundle-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-color);
    font-size: 19px;
    line-height: 22.27px;
    font-weight: 500px;
    border-width: 2px;
    border-color: var(--primary-color);
    border-style: solid;
    border-radius: 25px;
    padding: 9px 18px 9px 18px;
    margin-inline: 9px;
    cursor: pointer;
    background-color: var(--bundle-container-color);
}

.soft-bundle-bottom-component {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-inline: 10px;
    margin-top: 10px;
}

.soft-bundle-price-text {
    font-size: 16px;
    line-height: 18px;
    color: var(--bundle-text-color);
}

.soft-bundle-price {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: var(--bundle-text-color);
}

.soft-bundle-duration {
    font-size: 14px;
    font-weight: 400;
    color: var(--bundle-text-color);
}
