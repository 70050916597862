@font-face {
    font-family: 'ATT Aleck Sans';
    src: url('../../../public/fonts/ATTAleckSans_Rg.ttf');
}

@font-face {
    font-family: 'ATT Aleck Sans Bold';
    src: url('../../../public/fonts/ATTAleckSans_Bd.ttf');
}

@font-face {
    font-family: 'ATT Aleck Sans Italic';
    src: url('../../../public/fonts/ATTAleckSans_It.ttf');
}

@font-face {
    font-family: 'ATT Aleck Sans Bold Italic';
    src: url('../../../public/fonts/ATTAleckSans_BdIt.ttf');
}

:root {
    --font-name: 'ATT Aleck Sans', sans-serif;
    --font-name-bold: 'ATT Aleck Sans Bold', sans-serif;
    --font-name-italic: 'ATT Aleck Sans Italic', sans-serif;
    --font-name-bold-italic: 'ATT Aleck Sans Bold Italic', sans-serif;
}
