.my-subscription-tile-soft-bundle-container {
    background-color: var(--bundle-container-color);
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.my-subscription-tile-soft-bundle-container > .my-subscription-tile-container:hover {
    background-color: transparent;
}

.my-subscription-tile-soft-bundle-container
    > .my-subscription-tile-container:hover
    .my-subscription-tile-edit-icon-background {
    background-color: transparent;
}

.my-subscription-tile-soft-bundle-container > .my-subscription-tile-container:hover .my-subscription-tile-edit-icon {
    fill: #0057b8;
}

.my-subscription-tile-soft-bundle-title {
    max-width: 109px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--bundle-background-color);
    color: var(--secondary-text-color);
    padding-inline: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    gap: 4px;
    font-size: 16px;
    line-height: 18px;
    border-radius: 4px;
    margin-inline: auto;
}

.my-subscription-tile-soft-bundle-star-icon {
    fill: var(--secondary-text-color);
    width: 20px;
    height: 20px;
}
