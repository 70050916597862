.tags-wrapper {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.tag-wrapper {
    padding: 3px 6px;
    border-radius: 9px;
    background: rgba(255, 255, 255, 0.2);
    color: var(--text-color);
    font-size: 21px;
    font-style: normal;
}
