.epgHorizontal {
    width: 500px;
    height: 135px;
    border-radius: 12px;
    display: flex;
    color: var(--White-Theme, #FFF);
    padding: 10px;
}

.epgVertical {
    display: flex;
    width: 660px;
    height: 135px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 12px;
    color: var(--White-Theme, --primary-text-color);
    padding: 10px;
}

.epgFocused, .epg:hover {
    background: var(--white-20, rgba(255, 255, 255, 0.20));
}

.epgFocused .tileDescription, .epg:hover .tileDescription {
    display: none;
}

.epgFocused .tileBtns, .epg:hover .tileBtns {
    display: flex;
}

.btnDivHorizontal button {
    text-align: center;
    display: none;
    height: 100%;
    align-items: center;
    padding: 10px 20px 10px 20px;
    border-radius: 15px;
    border: 2px solid #fff;
    color: var(--primary-text-color);
    background-color: transparent;
}

.epgFocused .btnDivHorizontal, .epg:hover .btnDivHorizontal {
    display: grid;
}

.tileEPGDescription {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}