@import './layouts/bloc/Bloc.css';
@import './layouts/casting/Casting.css';
@import './layouts/channel/Channel.css';
@import './layouts/epg/EPG.css';
@import './layouts//episode/Episode.css';
@import './layouts/episodeResponsive/EpisodeResponsive.css';
@import './layouts/landscape/Landscape.css';
@import './layouts/live/Live.css';
@import './layouts/logo/Logo.css';
@import './layouts/poster/Poster.css';
@import './layouts/progressbar/ProgressBar.css';
@import './layouts/tilePoster/TilePoster.css';
@import './layouts/viewMore/ViewMore.css';

body {
    background-color: #313131;
}

.relative {
    position: relative;
}

.d-flex {
    display: flex;
}

.d-none {
    display: none;
}

.b16 {
    font-size: 16px;
    font-style: normal;
    line-height: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: start;
}
.b16twoLines {
    font-size: 16px;
    font-style: normal;
    line-height: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: start;
    vertical-align: middle;
}
.n14 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.flexBetween {
    justify-content: space-between;
}

.tileBtns {
    display: none;
    justify-content: space-between;
    margin-top: 10px;
    width: fit-content;
}

.tileBtns button,
.btnDivHorizontal button {
    padding: 10px 20px 10px 20px;
    border-radius: 15px;
    border: 2px solid #fff;
    color: var(--primary-text-color);
    background-color: transparent;
}

.tileBtns button:last-child {
    margin-left: 10px;
}

.tileDescription {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.tileDescriptionDefault {
    display: none;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.tileActive {
    border: 3px solid var(--focus-border-color);
}

.tileDetailImg {
    width: 240px;
    height: 135px;
    border-radius: 12px;
}

.tileDetailFocused .tileBtns,
.tileDetailWeb:hover .tileBtns {
    display: flex;
}

.tileDetailFocused .tileDescription,
.tileDetailWeb:hover .tileDescription,
.tileDetailFocused .futureHorizontal,
.tileDetailWeb:hover .futureHorizontal {
    display: none;
}

.tileDetailWeb:hover .tileDescriptionDefault,
.tileDetailFocused .tileDescriptionDefault {
    display: -webkit-box;
}

/* Pointers */

.pointerLeft {
    border: solid 10px transparent;
    border-right-color: var(--focus-border-color);
    position: absolute;
    margin: -85px 0 0 -20px;
}

.pointerRight {
    border: solid 10px transparent;
    border-left-color: var(--focus-border-color);
    position: absolute;
    margin: -85px -20px 0px 0;
    right: 0;
}

.pointerTop {
    border: solid 10px transparent;
    border-bottom-color: var(--focus-border-color);
    position: absolute;
    margin: -20px 0 0 110px;
    top: 0;
}

.pointerBottom {
    border: solid 10px transparent;
    border-top-color: var(--focus-border-color);
    position: absolute;
    margin-bottom: -24px;
    bottom: 0;
}

.tileDetailText {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
}

.actionBtn {
    position: absolute;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
    top: 0;
    width: 100%;
    background: #46464875;
}

.liveTag {
    position: absolute;
    width: 30px;
    height: 14px;
    top: 10px;
    left: 10px;
    border-radius: 3px;
    color: var(--primary-text-color);
    background-color: red;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.changeFlex {
    flex-direction: row-reverse;
}

.flexEnd {
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
}

.tileSelectedNumber {
    position: absolute;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    fill: var(--White-Theme, #fff);
    filter: drop-shadow(0px 8px 12px rgba(0, 0, 0, 0.5));
    font-size: 60px;
    background: var(--background-color);
}
