.vmPosterWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 240px;
    background: var(--Light-Theme, #12171b);
}

.vmTileWrapper {
    display: inline-flex;
    padding: 29px 63px 28px 63px;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
    background: var(--Light-Theme, #12171b);
}

.viewMore {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.viewMoreIcon svg {
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.viewMoreText {
    color: var(--White-Theme, #eee);
    text-align: center;

    /* N24 */
    font-size: 24px;
    font-style: normal;
    line-height: normal;
}

.vmTileWrapper:hover {
    box-shadow: 0px 8px 22px 0px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    background: #21262a;
    position: relative;
    z-index: 111;
    transform: scale(1.03);
    overflow: hidden;
    cursor: pointer;
    overflow: visible;
}

.vmPosterWrapper:hover {
    box-shadow: 0px 8px 22px 0px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    background: #21262a;
    position: relative;
    z-index: 111;
    transform: scale(1.03);
    overflow: hidden;
    cursor: pointer;
    overflow: visible;
}
