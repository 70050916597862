.toast-wrapper {
    position: absolute;
    background: var(--background-color);
    min-width: 350px;
    z-index: 999999;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    animation-duration: 1s;
    animation-name: show;
    box-shadow: 1px 1px 5px 1px rgb(81 81 81 / 82%);
}

.toast-wrapper.top-left {
    top: 15px;
    left: 15px;
}

.toast-close-icon-wrapper {
    padding: 2px 2px 0 2px;
    margin: 4px;
    border-radius: 4px;
    cursor: pointer;
}

.toast-close-icon-wrapper:hover {
    background: rgb(49 49 49 / 30%);
}

.toast-wrapper.toast-error {
    background: white;
}

.toast-wrapper.toast-warning {
    background: #ffff8c;
}

.toast-wrapper.toast-success {
    background: white;
}

.toast-wrapper.toast-plain {
    background: var(--background-color);
}

.toast-wrapper.toast-info {
    background: white;
}

.toast-wrapper.top-right {
    top: 15px;
    right: 20px;
}

.toast-wrapper.top-center {
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
}

.toast-wrapper.bottom-left {
    bottom: 15px;
    left: 15px;
}

.toast-wrapper.bottom-right {
    bottom: 15px;
    right: 20px;
}

.toast-icon-details-wrapper {
    display: flex;
    flex-grow: 1;
    padding: 15px;
    gap: 12px;
    align-items: center;
}

.toast-icon-wrapper {
    display: flex;
    align-items: center;
}

.toast-icon {
    width: 35px;
    height: 35px;
    fill: #2e2e2e;
}

.toast-success .toast-icon {
    fill: #21bc5f;
}

.toast-error .toast-icon {
    fill: #f32e13;
}

.toast-actions {
    padding-top: 2px;
}

.toast-wrapper.deletion.bottom-left,
.toast-wrapper.deletion.top-left {
    animation-duration: 0.7s;
    animation-name: deleteLeft;
}

.toast-wrapper.deletion.bottom-right,
.toast-wrapper.deletion.top-right {
    animation-duration: 0.7s;
    animation-name: deleteRight;
}

.toast-wrapper.deletion.top-center {
    animation-duration: 0.7s;
    animation-name: deleteCenter;
}

.toast-title {
}

@keyframes deleteLeft {
    from {
        left: 15px;
        opacity: 1;
    }

    to {
        left: -600px;
        opacity: 0;
    }
}

@keyframes deleteRight {
    from {
        right: 15px;
        opacity: 1;
    }

    to {
        right: -700px;
        opacity: 0;
    }
}

@keyframes deleteCenter {
    from {
        opacity: 1;
    }

    to {
        top: -400px;
        opacity: 0;
    }
}

@keyframes show {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
