.loader {
    -webkit-animation: spin 1.5s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
    height: 33px;
    width: 33px;
    background: linear-gradient(to right, rgb(212, 212, 212) 50%, rgba(0, 168, 224, 1) 50%);
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader-inner-circle {
    height: 19px;
    width: 19px;
    background: var(--background-color, white);
    border-radius: 50%;
    position: relative;
    z-index: 1;
}

.loader::before {
    content: '';
    position: absolute;
    right: 37%;
    top: 89%;
    transform: translateY(-50%);
    height: 7px;
    width: 7px;
    background: rgba(0, 168, 224, 1);
    border-radius: 50%;
}

.loader::after {
    content: '';
    position: absolute;
    right: 40%;
    top: 11%;
    transform: translateY(-50%);
    height: 7px;
    width: 7px;
    background: rgba(0, 168, 224, 1);
    border-radius: 50%;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loader-wrapper {
    background-color: rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 12000;
    backdrop-filter: blur(2px);
    position: absolute;
    top: 0;
    left: 0;
}
