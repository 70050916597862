.logoChannel {
    display: flex;
    padding: 6.667px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background: rgba(0, 0, 0, 0.50);
}

.logoChannel img {
    height: 30px;
    width: 46px;
}