.tilePoster,
.tilePosterRow {
    width: 200px;
    flex-shrink: 0;
    border-radius: 12px;
    color: #000;
    transition: all 0.3s;
    overflow: hidden;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.2);
    @media (max-width: 750px) {
        width: 120px;
    }
}

.poster {
    width: 180px;
    height: 240px;
    flex-shrink: 0;
    border-radius: 12px;
}

.tilePosterDescHovered {
    visibility: visible !important;
    cursor: pointer;
}

.tilePosterFocused,
.tilePosterHovered {
    border: 4px solid var(--primary-color);
    margin: -4px;
    position: relative;
    z-index: 1;
    transform: translate(0px, -10px);
    box-shadow: 0px 20px 30px rgba(0, 46, 98, 0.3);
    overflow: visible;
    border-radius: 12px;
}

.tilePosterDesc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--focus-text-color);
    visibility: hidden;
}

.tilePosterActif {
    position: relative;
    justify-content: center;
    display: flex;
    border: 4px solid var(--focus-border-color);
    border-radius: 12px;
    width: 180px;
    height: 240px;
    cursor: pointer;
}

.tilePosterActif img {
    width: 100%;
    border-radius: 8px;
    background: #21262a;
}

.tilePoster img {
    background: #21262a;
}

.tilePosterImg {
    position: relative;
    cursor: pointer;
    width: 200px;
    height: 300px;
    @media (max-width: 750px) {
        width: 120px;
        height: 170px;
    }
}

.tilePosterImg img {
    height: 100%;
    width: 100%;
    border-radius: 8px;
}
