.element-wrapper {
    display: flex;
    gap: 10px;
    align-items: initial;
    flex-direction: column;
    margin-top: 10px;
}

.inline-element {
    flex-direction: row;
    align-items: center;
}

.element-label {
    color: var(--primary-text-color);
}
