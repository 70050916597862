.mySwiperOverlap {
    position: fixed;
    background-color: var(--secondary-color);
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
}

/* .play-button-icon{
  position: absolute;
  top: 0;
  right: 0;
} */

.swiper-container {
    position: relative;
}

.swiper {
    overflow: visible !important;
}

.duo {
    width: 727px;
    height: 342px;
}

.tertio {
    width: 608px;
    height: 342px;
}

.quatro {
    width: 361px;
    height: 342px;
}

.divscroll {
    position: relative;
    top: 100%;
    height: 1000px;
    margin-top: 700px;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    /* background: #fff; */
    display: flex !important;
    justify-content: center;
    align-items: center;
    background-size: 100% 100%;
}

.slider,
.slider .imgs {
    width: 100%;
    height: 100%;
    max-height: 800px;
    position: relative;
}

.slider {
    height: 600px;
    max-height: 1200px;
}

.slider .imgs {
    /* display: flex;
        overflow: hidden; */
    width: 100%;
}

.slider .img {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: absolute;
    left: 100%;
    float: left;
    animation: 0.6s cubic-bezier(0.645, 0.045, 0.355, 1) 0s left;
    animation-fill-mode: forwards;
    background-size: cover !important;
    /* background-position: 50% !important; */
}

.slider .img span {
    padding: 7px 20px;
    background: rgba(0, 0, 0, 0.8);
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    color: #f0f0f0;
    font-size: 14px;
    margin-bottom: 40px;
}

@keyframes slideLeft {
    from {
        transform: translateX(20px);
    }
    to {
        transform: translateX(0px);
    }
}

@keyframes slideRight {
    from {
        transform: translateX(-20px);
    }
    to {
        transform: translateX(0px);
    }
}

.swiper-pagination {
    position: static !important;
    bottom: 0px !important;
    margin-top: 20px !important;
}

.swiper-pagination-bullet {
    border-radius: 10px !important;
    opacity: 0.5 !important;
    background: var(--background-color) !important;
    height: 10px !important;
    width: 10px !important;
}

.swiper-pagination-bullet-active {
    height: 10px !important;
    width: 30px !important;
    background: var(--background-color) !important;
    border-radius: 10px !important;
    opacity: 1 !important;
}

.hero-swiper-img {
    width: 100%;
    height: 100%;
    /* mask-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.6) 0%,
        rgba(0, 0, 0, 0.9) 20%,
        rgba(0, 0, 0, 0.9) 70%,
        rgba(0, 0, 0, 0) 100%,
        transparent
    );
    -webkit-mask-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.6) 0%,
        rgba(0, 0, 0, 0.9) 20%,
        rgba(0, 0, 0, 0.9) 70%,
        rgba(0, 0, 0, 0) 100%,
        transparent
    ); */
}

.hero-swiper-btn {
    display: inline-flex;
    padding: 10px 20px;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    border: 2px solid var(--White-Theme, #eee);
    box-shadow: 0px 8px 22px 0px rgba(0, 0, 0, 0.2);
    color: var(--White-Theme, #eee);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    line-height: 30px;
    background: transparent;
    cursor: pointer;
    position: absolute;
    bottom: 180px;
}

.mySwipergrid .swiper-slide {
    position: relative;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
    opacity: 1 !important;
    background: var(--background-color);
    transform: scale(1.15);
    transition: all 0.3s;
}

.heroSwiper:hover .swiper-button-next {
    opacity: 0.75;
    animation: slideLeft 0.2s ease-in;
}

.heroSwiper:hover .swiper-button-prev {
    opacity: 0.75;
    animation: slideRight 0.2s ease-in;
}

.mySwipergrid:hover .swiper-button-next {
    opacity: 0.75;
    animation: slideLeft 0.2s ease-in;
}

.mySwipergrid:hover .swiper-button-prev {
    opacity: 0.75;
    animation: slideRight 0.2s ease-in;
}

.swiper-container:hover .swiper-button-next {
    opacity: 0.75;
    animation: slideLeft 0.2s ease-in;
}

.swiper-container:hover .swiper-button-prev {
    opacity: 0.75;
    animation: slideRight 0.2s ease-in;
}

.swiper-pagination-bullet {
    border-radius: 10px !important;
    opacity: 0.5 !important;
    background: var(--background-color) !important;
    height: 10px !important;
    width: 10px !important;
}

.swiper-pagination-bullet-active {
    height: 10px !important;
    width: 30px !important;
    background: var(--background-color) !important;
    border-radius: 10px !important;
    opacity: 1 !important;
}

.swiper-barker-img {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    border: 4px solid transparent;
    margin: -4px;
    background: #21262a;
    transition: all 0.5s;
}

.swiper-barker-img:hover {
    transform: scale(1.08);
    border: 4px solid var(--focus-border-color);
    overflow: auto;
    z-index: 1;
}

.swiper-barker-slide {
    cursor: pointer;
}

.swiper-barker-slide:hover {
    z-index: 1;
}

.mySwipergrid .swiper-slide {
    position: relative;
}

.swiper-pagination-bullet {
    border-radius: 10px !important;
    opacity: 0.5 !important;
    background: var(--background-color) !important;
    height: 10px !important;
    width: 10px !important;
}

.swiper-pagination-bullet-active {
    height: 10px !important;
    width: 30px !important;
    background: var(--background-color) !important;
    border-radius: 10px !important;
    opacity: 1 !important;
}

.mySwipergrid .swiper-slide {
    position: relative;
}

.heroSwiper .swiper-pagination {
    position: absolute !important;
}

.infoBarkerImage {
    width: 100%;
    margin-bottom: -80px;
    mask-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.6) 0%,
        rgba(0, 0, 0, 0.9) 20%,
        rgba(0, 0, 0, 0.9) 70%,
        rgba(0, 0, 0, 0) 100%,
        transparent
    );
    -webkit-mask-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.6) 0%,
        rgba(0, 0, 0, 0.9) 20%,
        rgba(0, 0, 0, 0.9) 70%,
        rgba(0, 0, 0, 0) 100%,
        transparent
    );
}
.heroSwiper .swiper-slide {
    height: 800px;
    width: 100%;
}

.hero-video {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: inherit;
}

.hero-barker-video-overlay {
    z-index: 1;
    position: relative;
    height: 100%;
    opacity: 0.5;

    @media (max-width: 750px) {
        height: 100%;
    }
}

.media-player {
    width: 100%;
    height: 800px;
    position: relative;
    /* display: flex; */
    /* align-items: center; */
    background-color: var(--background-color);
    @media (max-width: 750px) {
        height: 600px;
    }
}

.media-player .hero-video {
    position: absolute;
    z-index: 0;
}

.hero-barker-video-conatiner-without-width {
    position: absolute;
    right: 0;
    width: 100%;
    height: 100%;
    float: right;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: justify;
    @media (max-width: 750px) {
        position: relative;
        right: unset;
        width: 100%;
        height: 100%;
        float: top;
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
}

.hero-barker-video-conatiner-with-width {
    position: absolute;
    right: 0;
    width: 65%;
    height: 100%;
    float: right;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: justify;
    @media (max-width: 750px) {
        position: relative;
        right: unset;
        width: 100%;
        height: 60%;
        float: top;
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
}

.media-player-content {
    z-index: 2;
    @media (max-width: 750px) {
        width: 100%;
        position: absolute;
        bottom: 13%;
        top: 10%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.media-palyer-price-label {
    font-size: 18px;
    margin-top: 15px;
    @media (max-width: 750px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.round-play-button {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: auto;
    margin-bottom: auto;
    margin-inline: auto;
    width: 80px;
    height: 80px;
    cursor: pointer;
    fill: transparent;
    z-index: 1;
    @media (max-width: 750px) {
        position: relative;
        left: unset;
        right: unset;
        top: unset;
        bottom: unset;
    }
}

.round-play-button:hover {
    transition: ease 0.4s;
    fill: white;
}

.media-palyer-subtitle {
    font-size: 21px;
    color: white;
    margin-bottom: 20px;
    @media (max-width: 750px) {
        text-align: start;
        padding-inline: 20px;
    }
}

.media-player-info {
    position: absolute;
    width: 30%;
    z-index: 2;
}

.media-player-info-left-top {
    left: 10%;
    top: 10%;
    text-align: start;
    @media (max-width: 750px) {
        margin-top: 100px;
        text-align: unset;
        position: relative;
        width: auto;
        left: unset;
    }
}
.media-player-info-left-bottom {
    left: 10%;
    bottom: 10%;
    text-align: start;
    @media (max-width: 750px) {
        margin-top: 100px;
        text-align: unset;
        position: relative;
        width: auto;
        left: unset;
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
}

.media-player-info-left-center {
    left: 10%;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    text-align: start;
    @media (max-width: 750px) {
        margin-top: 100px;
        text-align: unset;
        position: relative;
        width: auto;
        left: unset;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.media-player-info-center-top {
    left: 0;
    right: 0;
    margin-inline: auto;
    top: 10%;
    text-align: center;
    @media (max-width: 750px) {
        margin-top: 100px;
        text-align: unset;
        position: relative;
        width: auto;
        left: unset;
    }
}

.media-player-info-center-bottom {
    left: 0;
    right: 0;
    margin-inline: auto;
    bottom: 10%;
    text-align: center;
    @media (max-width: 750px) {
        margin-top: 100px;
        text-align: unset;
        position: relative;
        width: auto;
        left: unset;
    }
}

.media-player-info-center-center {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    @media (max-width: 750px) {
        margin-top: 100px;
        text-align: unset;
        position: relative;
        width: auto;
        left: unset;
        display: unset;
    }
}

.media-player-info-right-top {
    right: 10%;
    top: 10%;
    text-align: end;
    @media (max-width: 750px) {
        margin-top: 100px;
        text-align: unset;
        position: relative;
        width: auto;
        right: unset;
    }
}

.media-player-info-right-bottom {
    right: 10%;
    bottom: 10%;
    text-align: end;
    @media (max-width: 750px) {
        margin-top: 100px;
        text-align: unset;
        position: relative;
        width: auto;
        right: unset;
    }
}
.media-player-info-right-center {
    right: 10%;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
    text-align: end;
    z-index: 2;
    @media (max-width: 750px) {
        margin-top: 100px;
        text-align: unset;
        position: relative;
        width: auto;
        right: unset;
        display: unset;
    }
}

.media-palyer-title {
    font-size: 42px;
    color: var(--primary-text-color);
    margin-bottom: 20px;
    line-height: 48.3px;
    width: 620px;
}
@media (max-width: 750px) {
    .media-palyer-title {
        font-size: 32px;
        line-height: 37.5px;
        text-align: center;
        white-space: normal;
        overflow-wrap: break-word;
        width: 100%;
    }
}

.media-palyer-subtitle {
    font-size: 24px;
    color: var(--primary-text-color);
    margin-bottom: 20px;
    line-height: 33.6px;
    width: 620px;
    @media (max-width: 750px) {
        text-align: start;
        padding-inline: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        color: white;
        cursor: pointer;
        width: fit-content;
        transition: ease 0.4s;
        @media (max-width: 750px) {
            margin-inline: auto;
        }
    }
}

.media-play-btn-guest {
    font-size: 16px;
    padding-inline: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 2px solid var(--primary-text-color);
    border-radius: 25px;
    color: var(--primary-text-color);
    cursor: pointer;
    width: fit-content;
    transition: ease 0.4s;
    line-height: 20.94px;
    background-color: var(--background-color);
    @media (max-width: 750px) {
        margin-inline: auto;
        margin: auto;
    }
}

.media-play-btn {
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 18.4px !important;
    padding-inline: 20px;
    padding-top: 12.5px;
    padding-bottom: 12.5px;
    padding-left: 32px;
    padding-right: 32px;
    gap: 12px;
    border-radius: 25px;
    color: var(--primary-text-color);
    cursor: pointer;
    width: fit-content;
    transition: ease 0.4s;
    line-height: 18.4px;
    background-color: var(--primary-color);
    @media (max-width: 750px) {
        margin-inline: auto;
        display: flex;
    }
}

.media-play-btn-left {
    display: flex;
    justify-content: left;
    @media (max-width: 750px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.media-play-btn-right {
    display: flex;
    justify-content: end;
    @media (max-width: 750px) {
        justify-content: center;
    }
}

.media-play-btn-center {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 750px) {
        float: unset;
    }
}

.media-play-btn:hover {
    color: black;
    background-color: var(--secondary-color);
    color: var(--primary-text-color);
}

.swiper-large-barker-slide {
    height: 100% !important;
    width: 75% !important;
    cursor: pointer;
}

.justify-center {
    justify-content: center;
}

.large-barker-wrapper {
    margin-left: 12.5%;
}

.media-palyer-thumbnail {
    width: 160px;
    height: 90px;
    margin-bottom: 20px;
    border-radius: 12px;
    @media (max-width: 760px) {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.media-image-block {
    position: absolute;
    width: 70%;
    height: 100%;

    background-size: cover;
    background-repeat: round;
    @media (max-width: 750px) {
        position: relative;
        right: unset;
        width: 100%;
        height: 60%;
        float: top;
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
}

.media-image-block-left {
    float: right;
    right: 0;
}

.media-image-block-right {
    float: left;
    left: 0;
}
