.tileLandscapeDesc {
    display: flex;
    justify-content: space-between;
    padding: 0 3px;
    align-items: center;
    color: var(--focus-text-color);
    visibility: hidden;
    height: 40px;
    visibility: hidden;
}

.tileLandscapeDescHovered {
    visibility: visible;
    cursor: pointer;
}

.tileLandscapeHovered {
    margin: -8px;
    border-radius: 12px;
    background: var(--primary-color);
    box-shadow: 0 8px 22px #0003;
    z-index: 1;
    position: relative;
    cursor: pointer;
    transform: scale(1.15);
    border: 4px solid var(--focus-border-color);
}

.landscapeImg img {
    width: 320px;
    height: 180px;
    /* width: 240px;
    height: 135px; */
    border-radius: 12px;
    background: #21262a;
    transition: all 250ms;
    cursor: pointer;
    @media (max-width: 750px) {
        width: 170px;
        height: 95px;
    }
}

.landscapeImg {
    position: relative;
    height: 100%;
}

.landscapeImgHybrd {
    height: 240px;
}

.landscapeImgHybrd img {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: #21262a;
}

/* Hover and Focus Styles */
.tileLandscapeHover,
.tileLandscapeFocus {
    transition: all 0.3s;
    width: 240px;
}

.tileLandscapeHoverhbrd,
.tileLandscapeFocus {
    transition: all 0.3s;
    width: 100%;
}

/* Collection Styles */
.landscapeImg.collection img:hover {
    margin: -4px;
    border: 4px solid var(--primary-color);
    transform: translate(0px, -10px);
    box-shadow: 0px 20px 30px rgba(0, 46, 98, 0.3);
    /* transition:ease-out  .2s; */
    /* position: relative; */
    /* z-index: 1; */
}

.landscapeImgHybrd.collection img:hover {
    width: 27rem;
    height: 15rem;
    margin: -9px -16px;
    background: var(--primary-color);
}

/* Base Styles */
.landscapeImg.base img,
.landscapeImgHybrd.base img {
    padding: 10px;
}

/* Tile Icons */
.tileicons {
    display: flex;
    justify-content: space-around;
}

.tileicons div {
    display: flex;
    width: 28px;
    height: 28px;
    margin: 0 0 0 4px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 4px;
    border: 2px solid var(--focus-text-color);
    cursor: pointer;
}

.tileicons > div > svg > g > path {
    fill: var(--focus-text-color);
}

.tileicons div:hover {
    background: var(--focus-text-color);
    border: 2px solid var(--focus-text-color);
}

.tileicons > div:hover > svg > g > path {
    fill: var(--primary-color) !important;
}
