.button {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    text-transform: none;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    border-radius: 68px;
    transition: all 0.3s ease-out 0s;
    width: auto;
    min-width: 35px;
    padding: 7px 13px;
}

.button-icon {
    box-shadow: none;
    min-width: unset;
    padding: 2px 2px;
    border: none;
    background: transparent;
}

.webstv-btn-icon {
    padding: 1px;
    border-radius: 3px;
}
.btn-div-menu-icons-style {
    width: 30px;
    height: 30px;
    justify-content: center;
    margin-top: 10px;
    margin-right: 15px;
    margin-left: 15px;
    margin-bottom: 3px;
    @media (max-width: 750px) {
        margin-bottom: 0px;
    }
}
.menu-button-style {
    box-shadow: none;
    min-width: unset;
    padding: 0px 0px;
    border: none;
    background: transparent;
    cursor: pointer;
}
.menu-header-icon-style {
    fill: var(--primary-color);
    width: 24px;
    height: 24px;
}
.menu-btn-icon {
    fill: black;
    height: 20px;
    width: 20px;
}

.webstv-btn-icon:hover {
    background: rgb(49 49 49 / 30%);
}

.button-primary {
    box-shadow:
        rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
        rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
        rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    background-color: var(--background-color);
    color: rgb(0, 0, 0);
    border: 1px solid white;
}

.button-secondary {
    border: 0.13rem solid rgb(255, 255, 255);
    background-color: transparent;
    color: var(--primary-text-color);
}

.button-primary:hover {
    background-color: var(--button-background-color);
    color: var(--primary-text-color);
    border: 1px solid var(--button-background-color);
}

.button-secondary:hover {
    background-color: var(--button-background-color);
    color: var(--primary-text-color);
    border-color: var(--button-background-color);
    border-width: 0.13rem;
    text-decoration: none;
}

.notification-dot {
    height: 6px;
    width: 6px;
    background-color: var(--primary-color);
    border-radius: 50%;
    border: 1px solid white;
    display: inline-block;
    position: relative;
    top: -19px;
    left: 8px;
}
