.mainContainer {
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 1);
    padding-top: 80px;
    padding-bottom: 80px;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.content-wall-title-container {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 40px;
}

.content-wall-title {
    font-size: 42px;
    text-align: center;
    color: var(--text-color);
    @media (max-width: 750px) {
        font-size: 32px;
    }
}
.tileContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 40px;
    @media (max-width: 750px) {
        gap: 15px;
    }
}

.tilePortrait .tile {
    width: 200px;
    height: 300px;
    @media (max-width: 750px) {
        width: 120px;
        height: 170px;
    }
}

.tilePortrait .thumbnail {
    height: 100%;
}

.content-wall-logo {
    width: 80px;
    height: 45px;
    border-radius: 6px;
    background-size: contain;
}
