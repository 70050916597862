.episode {
    display: flex;
    width: 660px;
    height: 135px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 12px;
    color: var(--White-Theme, #FFF);
    padding: 10px;
}

.episodeFocused, .episode:hover {
    background: var(--white-20, rgba(255, 255, 255, 0.20));
}

.tileDescription, .tileDescriptionDefault {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.tileDescriptionDefault {
    display: none;
}

.episode:hover .tileDescription, .episodeFocused .tileDescription {
    display: none;
}

.episode:hover .tileDescriptionDefault, .episodeFocused .tileDescriptionDefault {
    display: -webkit-box;
}

.episodeFocused .tileBtns, .episode:hover .tileBtns {
    display: flex;
}