.simplePoster {
    width: 180px;
    height: 240px;
    flex-shrink: 0;
    border-radius: 12px;
    background: #21262a;
    transition: all 250ms;
}

.simplePosterFocused,
.simplePoster:hover {
    margin: -4px;
    border: 4px solid var(--primary-color);
    transform: translate(0px, -10px);
    box-shadow: 0px 20px 30px rgba(0, 46, 98, 0.3);
    cursor: pointer;
}
