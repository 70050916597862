.menuWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgb(9, 8, 8);
    background: var(--background-color);
    overflow: scroll;
    height: 90px;
    width: -moz-available;
    padding: 0px;
    overflow-y: hidden;
    position: relative;
    z-index: 2;
    box-shadow: 0px 10px 30px 0px #00000033;
    @media (max-width: 750px) {
        /* display: flex; */
        /* flex-direction: row; */
        /* justify-content: space-between; */
        /* align-items: center; */
    }
}

.menuWrapper::-webkit-scrollbar {
    display: none;
}

.logo {
    margin-top: -5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    height: 30px;
    margin-left: 10rem;
    @media (max-width: 750px) {
        margin-left: 2rem;
    }
}

.logo-img {
    width: 100px;
    height: 100px;
    background-size: contain;
}
.menuOptionsWrapper {
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 15px;
    color: var(--primary-color);
    margin-right: 5rem;
    align-items: center;
    @media (max-width: 750px) {
        display: flex;
        width: 100%;
        margin-right: 10px;
        justify-content: flex-end;
        gap: 0px;
    }
}

.menuOption {
    border-radius: 25px;
    padding: 10px 20px;
    cursor: pointer;
    color: var(--text-color);
    text-align: center;
    /* B18 */
    font-size: 18px;
    font-style: normal;
    line-height: 20px; /*111.111%*/
    transition: box-shadow 0.3s ease-in-out;
    display: flex;
    align-items: baseline;
    @media (max-width: 750px) {
        display: none;
    }
}

.menuOptionBtn {
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 25px;
    background: var(--primary-color);
    border: none;
    color: var(--primary-text-color);
    font-size: 21px;
    line-height: 24.61px;
    text-align: center;
    @media (max-width: 750px) {
        display: none;
    }
}
.menuOptionBtnSignup {
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 25px;
    text-align: center;
    background: var(--background-color);
    border: 2px solid var(--primary-color);
    color: var(--primary-color) !important;
    /* position: relative; */
    /* right: 300px; */
    @media (max-width: 750px) {
        display: none;
    }
}
.menuOptionSelected {
    color: var(--primary-color);
    fill: var(--primary-color);
}

.menuOption:hover {
    background: var(--background-color);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}

.menuOptionLogo {
    width: 20px;
    height: 20px;
    @media (max-width: 750px) {
        width: 24px;
        height: 24px;
    }
}
.menuOptionTxt {
    position: relative;
    bottom: 3px;
    left: 5px;
    font-size: 18px;
    line-height: 25.2px;
    text-wrap: nowrap;

    @media (max-width: 480px) {
        font-size: 23px;
        line-height: 26.95px;
        white-space: normal;
        overflow-wrap: break-word;
        width: 100%;
        display: flex;
        margin-right: 1rem;
    }
}

.action {
    display: flex;
    align-items: center;
    gap: 20px;
}

.actionItemIcon {
    margin-top: 12px;
    text-align: center;
    border-radius: 25px;
    transition: box-shadow 0.3s ease-in-out;
    cursor: pointer;
    @media (max-width: 750px) {
        display: none;
        margin-top: 0px;
    }
}
.actionItemIcon:nth-child(2) {
    @media (max-width: 750px) {
        display: block;
        padding-bottom: 10px;
        padding-left: 0px;
    }
}
.logedinLogo {
    width: 24px;
    height: 24px;
    padding: 10px;
}

.actionItemIcon:hover {
    background: var(--background-color);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}

.actionItem:hover {
    background: #ffffff33;
}

.actionItemLabel {
    cursor: pointer;
}

.top-menu {
    position: fixed;
    z-index: 999;
    width: 100%;
    @media (max-width: 750px) {
        z-index: 999;
    }
}

.burger {
    display: none;
    z-index: 999;
    @media (max-width: 750px) {
        display: flex;
        margin-right: 10px;
        gap: 10px;
    }
}

.burger-icon {
    fill: black;
    width: 38px;
    height: 38px;
}

.rootIcons img,
.rootIcons svg {
    width: 24px;
    height: 24px;
}

.rootIconsSelected,
.rootIconsSelected svg path {
    color: var(--primary-color);
    fill: var(--primary-color) !important;
}

.search-icon {
    position: relative;
    fill: black;
    width: 32px;
    height: 32px;
    top: 3px;
    left: 8px;
}
.side-panel-clone {
    display: none;
    @media (max-width: 750px) {
        display: block;
        height: 100%;
        width: 0;
        position: fixed;
        z-index: -1;
        top: 0;
        right: 0;
        overflow: hidden;
        transition: 0.2s;
    }
}
.side-panel-clone > .side-panel {
    display: none;
    @media (max-width: 750px) {
        display: block;
        height: 100%;
        width: 0;
        position: fixed;
        z-index: -1;
        top: 0;
        right: 0;
        background-color: var(--background-color);
        overflow-x: hidden;
        transition: 0.2s;
    }
}

.sideMenuOptionsWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: var(--primary-text-color);
    align-items: flex-start;
    position: relative;
    top: 130px;
    left: 20px;
    gap: 10px;
}

.sideMenuOption {
    display: flex;
    border-radius: 25px;
    padding: 10px 20px;
    cursor: pointer;
    color: var(--White-Theme, #0a0606);
    gap: 12px;
    /* B18 */
    font-size: 18px;
    font-style: normal;
    line-height: 20px; /*111.111%*/
}
.sideMenuOption > svg,
.sideMenuOption > svg path {
    fill: var(--text-color);
}

.sideMenuOptionSelected {
    color: var(--primary-color);
    fill: var(--primary-color);
}

.sideMenuOptionBtn {
    cursor: pointer;
    /* padding: 11px, 22px, 11px, 22px; */
    border-radius: 25px;
    text-align: center;
    background: var(--primary-color);
    border: none;
    color: var(--primary-text-color);
    width: 85%;
    height: 50px;
    /* position: relative;
    top: 163px;
    left: 110px; */
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sideMenuOptionBtnSignup {
    cursor: pointer;
    text-align: center;
    background: var(--background-color);
    border: 2px solid var(--primary-color);
    color: var(--primary-color) !important;
    position: relative;
    top: 130px;
    left: 20px;
    padding: 9px 18px 9px 18px;
    gap: 10px;
    border-radius: 25px;
}

.sideMenuActionItemIcon {
    cursor: pointer;
    padding: 10px 10px;
    border-radius: 4px;
    @media (max-width: 750px) {
        margin-left: 10px;
    }
}

.sideMenuActionItemIcon1 {
    margin-bottom: -10px;
}

.sideMenuActionItemIconLabel {
    color: black;
    font-size: 23px;
    line-height: 26.95px;
    text-align: center;
}

.separator {
    position: relative;
    width: 220px;
    height: 2px;
    top: 75px;
    left: 20px;
    gap: 0px;
    border-radius: 2px 0px 0px 0px;
    background: #f5f5f5;
}

.sideMenuLinks {
    position: relative;
    width: Hug (113px) px;
    height: Hug (190px) px;
    top: 100px;
    left: 20px;
    gap: 20px;
    cursor: pointer;
}

.sideMenuText {
    font-size: 18px;
    line-height: 20px;
    text-align: left;
    color: #888888;
    padding: 15px;
}

.social-icon-list {
    padding: 5px;
    fill: black;
}

.socialHandlerList {
    display: flex;
    flex-direction: row;
    width: 219px;
    height: 36px;
    top: 166px;
    gap: 9px;
    position: relative;
}

.actionItemIcon #subclass {
    display: none;
    width: 130px;
    padding: 10px;
    position: fixed;
    z-index: 99999;
}

.actionItemIcon #subclass:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
}

.hideDiv {
    display: none;
}
